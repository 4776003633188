/* eslint-env browser */
import React from "react";
import {useIdleTimer} from "react-idle-timer";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../axiosClient.js";
import useJwt from "./contexts/useJwt.jsx";
import useLogout from "./hooks/useLogout.jsx";

function AutoLogout({
  // Props
  throttle,
  warningSeconds,
}) {
  //---------------------------------------------------------------------------
  // Global variables & state
  //---------------------------------------------------------------------------
  const [idle, setIdle] = React.useState(false);
  const {username, setJwt, expiration, unauthorized} = useJwt();

  //---------------------------------------------------------------------------
  // Use the JWT expiration to calculate when to report IDLE
  //---------------------------------------------------------------------------
  const currentTime = Math.floor(Date.now() / 1000);
  // Make sure we have 5 seconds left when we auto-logout so that the JWT is still valid
  // for any cleanup API calls we need to make.
  const remainingSeconds = expiration - currentTime - 5;
  let warningMs;
  if (remainingSeconds > warningSeconds) {
    warningMs = warningSeconds * 1000;
  } else if (remainingSeconds > 0) {
    warningMs = remainingSeconds * 1000;
  } else {
    warningMs = 1000;
  }
  const timeoutMs =
    remainingSeconds > warningSeconds ? (remainingSeconds - warningSeconds) * 1000 : warningMs;

  //---------------------------------------------------------------------------
  // This one-shot timer is used to go to the login page. Until that page is
  // converted to React, we'll use query params to pass the redirect and message.
  //---------------------------------------------------------------------------
  const logout = useLogout();
  const timer = React.useRef();
  const goToLoginPage = React.useCallback(async () => {
    clearTimeout(timer.current);
    timer.current = undefined;
    logout("You have been logged out.");
  }, [logout]);

  if (remainingSeconds > 0 && remainingSeconds <= warningSeconds && !idle) {
    setIdle(true);
    clearTimeout(timer.current);
    timer.current = setTimeout(goToLoginPage, warningMs);
  }

  //---------------------------------------------------------------------------
  // Monitor localStorage for changes (support cross-tab stuff & PT)
  //---------------------------------------------------------------------------
  React.useEffect(() => {
    if (remainingSeconds <= 0 || unauthorized) {
      goToLoginPage(unauthorized);
    }
  }, [expiration, goToLoginPage, remainingSeconds, unauthorized]);

  //---------------------------------------------------------------------------
  // Idle state - this goes true to trigger the logout warning
  //---------------------------------------------------------------------------
  const handleOnIdle = () => {
    if (!expiration || remainingSeconds <= 0) {
      return;
    }
    setIdle(true);
    clearTimeout(timer.current);
    timer.current = setTimeout(goToLoginPage, warningMs);
  };

  //---------------------------------------------------------------------------
  // As long as the user is active, refresh the JWT every <throttling> seconds
  //---------------------------------------------------------------------------
  const refreshJwtOnAction = React.useCallback(async () => {
    if (!expiration || remainingSeconds <= 0 || !username) {
      return;
    }
    clearTimeout(timer.current);
    timer.current = undefined;
    try {
      const response = await axios({
        method: "post",
        url: "refreshJwt",
      });
      const newJwt = response.headers["access-token"];
      if (typeof newJwt === "string" && newJwt.length >= 16) {
        setJwt(newJwt);
      } else {
        // This generally means that the user has been disabled
        goToLoginPage();
      }
    } catch (err) {
      // Do nothing
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [expiration, goToLoginPage, username, remainingSeconds, setJwt]);

  //---------------------------------------------------------------------------
  // This sets up the actual idle detection system
  //---------------------------------------------------------------------------
  const {start} = useIdleTimer({
    timeout: timeoutMs,
    onIdle: handleOnIdle,
    onAction: refreshJwtOnAction,
    throttle,
    stopOnIdle: true,
    crossTab: true,
  });
  const onClick = () => {
    setIdle(false);
    refreshJwtOnAction();
    start();
  };

  if (!expiration || remainingSeconds <= 0) {
    return null;
  }

  //---------------------------------------------------------------------------
  // Render the snackbar if needed
  //---------------------------------------------------------------------------
  return (
    <Snackbar
      data-cy="auto-logout-notifier"
      open={idle}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      sx={{mt: 6.5}}
    >
      <SnackbarContent
        message="You will be logged out soon."
        action={
          <Button
            aria-label="close"
            data-cy="auto-logout-refresh-button"
            color="inherit"
            size="small"
            onClick={onClick}
          >
            Stay logged in
          </Button>
        }
      />
    </Snackbar>
  );
}

AutoLogout.propTypes = {
  throttle: PropTypes.number.isRequired,
  warningSeconds: PropTypes.number.isRequired,
};

// Export a memo so the component is only re-rendered if the props change, not if the parent re-renders.
const memoAutoLogout = React.memo(AutoLogout);
export default memoAutoLogout;
