import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

function TableLoading({marginTop = 10, size = 100}) {
  return (
    <Grid container>
      <Grid item sx={{mt: marginTop}} xs={12} align="center">
        <CircularProgress color="secondary" size={size} data-cy="loading-circle" />
      </Grid>
    </Grid>
  );
}

TableLoading.propTypes = {
  marginTop: PropTypes.number,
  size: PropTypes.number,
};

export default TableLoading;
