import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

function Pagination({pageSize, count, page, setPage, "data-cy": dataCy, children}) {
  const start = page * pageSize + 1;
  const end = Math.min((page + 1) * pageSize, count);
  const maxPage = Math.ceil(count / pageSize) - 1;

  const setFirstPage = () => setPage(0);
  const setPrevPage = () => setPage((previous) => previous - 1);
  const setNextPage = () => setPage((previous) => previous + 1);
  const setLastPage = () => setPage(maxPage);

  return (
    <Box data-cy={dataCy}>
      <Grid container sx={{p: 1.5}} style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
        <Grid item xs="auto">
          <IconButton color="secondary" onClick={setFirstPage} disabled={page <= 0}>
            <FirstPage />
          </IconButton>
          <IconButton color="secondary" onClick={setPrevPage} data-cy="prev-page-button" disabled={page <= 0}>
            <ChevronLeft />
          </IconButton>
        </Grid>

        {children && (
          <Grid item xs align="right" p={2}>
            {children}
          </Grid>
        )}
        <Grid item xs align={children ? "left" : "center"} p={2}>
          <Typography fontSize="0.875rem">
            Showing {start} - {end} of {count}
          </Typography>
        </Grid>

        <Grid item xs="auto" align="right">
          <IconButton
            color="secondary"
            onClick={setNextPage}
            data-cy="next-page-button"
            disabled={page >= maxPage}
          >
            <ChevronRight />
          </IconButton>
          <IconButton color="secondary" onClick={setLastPage} disabled={page >= maxPage}>
            <LastPage />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  "data-cy": PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(Pagination);
